import { isPlatformServer } from '@angular/common';
import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
  makeStateKey,
  TransferState,
} from '@angular/core';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { Request, Response } from 'express';
import UAParser from 'ua-parser-js';

@Injectable({
  providedIn: 'root',
})
export class UserAgent {
  constructor(
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: Request,
    @Optional() @Inject(RESPONSE) private response: Response,
  ) {}

  isMobileDevice(): boolean {
    return this.getUserAgent().getDevice().type !== undefined;
  }

  getUserAgent(): UAParser.UAParserInstance {
    if (isPlatformServer(this.platformId)) {
      throw new Error(
        'getUserAgent() cannot be used on the server as it will have unexpected effects due to the User-Agent not being part of the cache key in Varnish',
      );
    }
    return new UAParser(window.navigator.userAgent);
  }

  isBot(): boolean {
    const stateKey = makeStateKey<boolean>('isBot');

    if (isPlatformServer(this.platformId)) {
      // We set this header in Fastly. See D157516
      const isBot = this.request.get('X-User-Agent-Bot') === 'true';
      this.transferState.set(stateKey, isBot);
      this.response.append('Vary', 'X-User-Agent-Bot');

      return isBot;
    }

    return this.transferState.get(stateKey, false);
  }
}
